<template>
	<v-dialog v-model="show" :max-width="400">
		<v-card>
			<v-card-title>
				Admins
				<v-spacer />
				<Button @click="searchAdmin"><v-icon>mdi-plus</v-icon></Button>
			</v-card-title>
			<v-divider />

			<v-card-text class="px-0" v-if="admins.length">
				<v-list-item
						v-for="admin in admins"
						:key="admin.user_id"
						@click="() => {}"
				>
					<Avatar
						:source="admin.profile_image"
						class="mr-2"
					/>
					<v-list-item-content @click="gotoProfile(admin)">
						<v-list-item-title>
							<v-list-item-content>
								{{admin.display_name}} <span class="caption text-blue">({{admin.at_name}})</span>
							</v-list-item-content>

						</v-list-item-title>
					</v-list-item-content>

					<v-list-item-action>
						<Button icon @click="removeAdmin(admin)">
							<v-icon color="red">mdi-close-circle</v-icon>
						</Button>
					</v-list-item-action>
				</v-list-item>
			</v-card-text>
			<v-card-text v-else class="pt-4">
				No admins added
			</v-card-text>
		</v-card>

		<Search :types="type === `private` ? `public` : `private`" ref="search" />
	</v-dialog>
</template>

<script>
	import { globalMixIn } from '@/App'
	import Search from "@/components/dialogs/Search"

	export default {
		components: {
			Search,
		},
		data: () => ({
			show: false,
		}),
		name: `ProfileAdmins`,
		props: [`admins`, `profile`, `type`, `at_name`],
		methods: {
			open() {
				return new Promise((resolve, reject) => {
					this.resolve = resolve
					this.reject = reject
					this.show = true
				})
			},
			close() {
				this.show = false
			},
			searchAdmin() {
				this.$refs.search.open().then(o => this.addAdmin(o))
			},
			gotoProfile(admin) {
				this.close()
				this.$router.push({name: 'Profile', params: {id: admin.at_name}})
			},
			addAdmin(profile) {
				this.$httpInt.post(`/v2/app/account/${this.at_name}/admins`, { at_name: profile.at_name })
					.then(() => {
						this.$root.$snackbar('Admin added')
						this.close()
						this.resolve()
					})
			},
			removeAdmin(admin) {
				this.$httpInt.delete(`/v2/app/account/${this.at_name}/admins/${admin.at_name}`)
					.then(() => {
						this.$root.$snackbar('Admin removed')
						this.close()
						this.resolve()
					})
			},
		},
		mixins: [globalMixIn],
	}
</script>