<template>
	<v-dialog
			v-model="show"
			persistent
			max-width="600px"
	>
		<v-card>
			<v-card-title>
				Create post
				<v-spacer />
				<Button icon text @click="close">
					<v-icon>mdi-close</v-icon>
				</Button>
			</v-card-title>

			<v-divider />

			<v-card-text>
				<v-form>
					<v-row>
						<v-col>
							<v-autocomplete
									v-model="authors.selected"
									:items="authors.items"
									:search-input.sync="authors.search"
									item-text="at_name"
									item-value="at_name"
									:return-object="true"
									label="From"
									hide-details
									required
									outlined
									placeholder=" "
							>
								<template slot="selection" slot-scope="data">
									{{ data.item.display_name }} (@{{ data.item.at_name }})
								</template>
								<template slot="item" slot-scope="data">
									{{ data.item.display_name }} (@{{ data.item.at_name }})
								</template>
							</v-autocomplete>
						</v-col>

					</v-row>

					<v-row>
						<v-col>
							<v-select
									v-model="type"
									:items="[`love`, `idea`, `warning`]"
									label="Type"
									hide-details
									required
									outlined
									placeholder=" "
							/>
						</v-col>
						<v-col v-if="false">
							<v-menu
									ref="datemenu"
									v-model="date.menu"
									:close-on-content-click="false"
									:return-value.sync="date.date"
									transition="scale-transition"
									offset-y
									min-width="290px"
							>
								<template v-slot:activator="{ on }">
									<v-text-field
											v-model="date.date"
											readonly
											v-on="on"
											hide-details
											label="Date"
											outlined
											placeholder=" "
									/>
								</template>
								<v-date-picker v-model="date.date" no-title scrollable>
									<v-spacer />
									<Button text type="submit" @click="date.menu = false">Cancel</Button>
									<Button text type="submit" @click="$refs.datemenu.save(date.date)">OK</Button>
								</v-date-picker>
							</v-menu>
						</v-col>
						<v-col v-if="false">
							<v-menu
									ref="timemenu"
									v-model="time.menu2"
									:close-on-content-click="false"
									:nudge-right="40"
									:return-value.sync="time.time"
									transition="scale-transition"
									offset-y
									max-width="290px"
									min-width="290px"
							>
								<template v-slot:activator="{ on }">
									<v-text-field
											v-model="time.time"
											readonly
											hide-details
											v-on="on"
											label="Time"
											outlined
											placeholder=" "
									/>
								</template>
								<v-time-picker
										v-if="time.menu2"
										v-model="time.time"
										full-width
										format="24hr"
										@click:minute="$refs.timemenu.save(time.time)"
								/>
							</v-menu>
						</v-col>
					</v-row>
					<v-row>
						<v-col
								v-if="['love','idea','warning'].includes(type)"
						>
							<v-autocomplete
									v-model="recipients.selected"
									:items="recipients.items"
									:search-input.sync="recipients.search"
									:return-object="true"
									item-text="at_name"
									item-value="at_name"
									label="Recipient"
									hide-details
									outlined
									placeholder=" "
							>
								<template slot="selection" slot-scope="data">
									{{ data.item.display_name }} (@{{ data.item.at_name }})
								</template>
								<template slot="item" slot-scope="data">
									{{ data.item.display_name }} (@{{ data.item.at_name }})
								</template>
							</v-autocomplete>
						</v-col>
					</v-row>

					<v-row>
						<v-col
								v-if="['love','idea','warning'].includes(type)"
						>
							<v-text-field
									v-model="title"
									label="Title"
									value=""
									counter
									maxlength="120"
									hide-details
									outlined
									placeholder=" "
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-textarea
									dense
									v-model="message"
									label="Message"
									counter
									maxlength="10000"
									hide-details
									outlined
									placeholder=" "
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<VImage ref="image" scalable movable />
						</v-col>
					</v-row>
				</v-form>
			</v-card-text>

			<v-card-actions>
				<v-spacer />
				<Button type="submit" @click="saveContent" :loading="loading">Post</Button>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import VImage from '@/components/material/Image'
	import { globalMixIn } from '@/App'
	import dayjs from 'dayjs'
	import 'dayjs/locale/sv'
	const utc = require('dayjs/plugin/utc')
	dayjs.extend(utc)

	dayjs.locale(`sv`)

	export default {
		components: {
			VImage,
		},
		computed: {
			review() {
				let data = {
					title: this.title,
					text: this.message,
					type: `review`,
					review_type: this.type,
					author_id: this.authors.selected && this.authors.selected.user_id || null,
					recipient_id: this.recipients.selected && this.recipients.selected.user_id || null,
				}
				if (this.image) data.thumbnails = this.image.thumbnails
				return data
			},
			post() {
				let data = {
					title: this.title,
					text: this.message,
					type: `post`,
					author_id: this.authors.selected && this.authors.selected.user_id || null,
				}
				if (this.image) data.thumbnails = this.image.thumbnails
				return data
			},
		},
		data: () => ({
			authors: {
				items: [],
				selected: null,
				search: '',
			},
			loading: false,
			recipients: {
				items: [],
				selected: null,
				search: '',
			},
			date: {
				date: null,
				menu: false,
				modal: false,
				menu2: false,
			},
			image: null,
			message: ``,
			time: {
				time: null,
				menu2: false,
				modal2: false,
			},
			show: false,
			type: ``,
			title: ``,
		}),
		methods: {
			close() {
				this.show = false
				this.reset()
			},
			open(object) {
				object && object.author && this.getUser(object.author)
				this.show = true
			},
			getUser(author) {
				this.$httpInt.get(`/v2/app/account/${author}`)
					.then(res => {
						this.authors.items.push(res.data.result)
						this.authors.selected = res.data.result
					})
			},
			reset() { Object.assign(this.$data, this.$options.data.apply(this)) },
			findAuthor(v) {
				this.$httpInt.get(`/v2/app/account?q=${v}`)
					.then(res => this.authors.items = res.data.result)
			},
			findTarget (v) {
				this.$httpInt.get(`/v2/app/account?q=${v}&type=public`)
					.then(res => this.recipients.items = res.data.result)
			},
			saveContent () {
				this.loading = true
				if (this.image) {
					this.$refs.image.uploadImage()
						.then(res => {
							this.image = res.result
							this.savePost()
						}).finally(() => this.loading = false)
				} else {
					this.savePost()
				}
			},
			savePost() {
				if ([`love`,`idea`,`warning`].includes(this.type)) {
					this.type = this.type.replace(`warning`, `bomb`)
					this.$httpInt.post(`/v2/app/posts`, this.review)
						.then(() => this.close())
						.finally(() => this.loading = false)
				} else {
					this.$httpInt.post(`/v2/app/posts`, this.post)
						.then(() => this.close())
						.finally(() => this.loading = false)
				}
			},
		},
		mixins: [globalMixIn],
		name: `NewPost`,
		watch: {
			'recipients.search' (val) {
				val && val !== this.select && this.$debounce(() => this.findTarget(val), 300)
			},
			'authors.search' (val) {
				val && val !== this.select && this.$debounce(() => this.findAuthor(val), 300)
			},
		},
	}
</script>

<style>
	.v-time-picker-clock {
		width: 270px !important;
	}
</style>