<template>
	<v-dialog v-model="show" width="700" @keydown.esc="cancel">
		<v-card>
			<v-card-title>
				Merge two profiles
			</v-card-title>
			<v-divider />
			<v-card-text class="pa-6">
				<v-row>
					<v-col>
						<div class="d-flex justify-space-between">
							<div align="center">
								<v-list-item-title class="text-h6 mb-1" align="center">Remove</v-list-item-title>
								<v-card width="300" @click="searchRemove()">
									<v-list-item v-if="remove">
										<v-list-item-content>
											<Avatar :source="remove.small_profile_image || `private-profile`" />
											<v-list-item-title class="mb-1">{{ remove.display_name }}</v-list-item-title>
											<v-list-item-subtitle>@{{ remove.at_name }}</v-list-item-subtitle>
											<v-list-item-subtitle>{{ remove.email }}</v-list-item-subtitle>
										</v-list-item-content>
									</v-list-item>

									<v-list-item v-else>
										<v-list-item-content>
											<v-list-item-title class="mb-1">Click to find a profile</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-card>
							</div>

							<div align="center">
								<v-list-item-title class="text-h6 mb-1">Keep</v-list-item-title>
								<v-card width="300" @click="searchKeep()">
									<v-list-item v-if="keep">
										<v-list-item-content>
											<Avatar :source="keep.small_profile_image || `private-profile`" />
											<v-list-item-title class="mb-1">{{ keep.display_name }}</v-list-item-title>
											<v-list-item-subtitle>@{{ keep.at_name }}</v-list-item-subtitle>
											<v-list-item-subtitle>{{ keep.email }}</v-list-item-subtitle>
										</v-list-item-content>
									</v-list-item>

									<v-list-item v-else>
										<v-list-item-content>
											<v-list-item-title class="mb-1">Click to find a profile</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-card>
							</div>
						</div>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						All content (comments, posts, reviews), network (followers, followings) and actions (shares, agrees) from the removed account will be merged to kept account. The removed account will be unavailable to reach after merge.
					</v-col>
				</v-row>

				<v-row>
					<v-col class="pb-0">
						<Button
							:disabled="!remove || !keep"
							type="submit"
							block
							@click.native="accept"
							:loading="loading"
						>
							Merge accounts
						</Button>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<Search :types="type" ref="search" />
	</v-dialog>
</template>

<script>
import Search from "@/components/dialogs/Search"

export default {
	components: {
		Search,
	},
	data: () => ({
		show: false,
		remove: null,
		keep: null,
		loading: false,
	}),
	name: `MergeAccounts`,
	methods: {
		open(remove, keep) {
			this.reset()
			this.show = true
			if (remove) this.$nextTick(() => this.remove = this.searchRemove(remove))
			if (keep) this.$nextTick(() => this.keep = this.searchKeep(keep))
			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},
		close() {
			this.show = false
		},
		accept() {
			this.loading = true

			this.$httpInt.post(`/v2/app/account/merge`, {
				keep_account: {
					at_name: this.keep.at_name,
				},
				replace_account: {
					at_name: this.remove.at_name,
				}
			})
				.then(() => {
					this.resolve()
					this.close()
					this.$router.push({name: `Profile`, params: {id: this.keep.at_name}})
				})
				.then(() => this.loading = false)
		},
		cancel() {
			this.reject()
			this.close()
		},
		searchRemove(at_name) {
			this.$refs.search.open({at_name}).then(o => this.remove = o)
		},
		searchKeep(at_name) {
			this.$refs.search.open({at_name, type: `accounts`}).then(o => this.keep = o)
		},
		reset() {
			Object.assign(this.$data, this.$options.data.apply(this))
		},
	},
	props: [`type`],
}
</script>