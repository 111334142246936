<template>
	<v-container
			fluid
			v-if="!loading"
	>
		<v-alert
			v-if="not_found"
			prominent
			outlined
			text
			type="error"
		>
			<v-row align="center">
				<v-col class="grow">
					<h2>"{{$route.params.id}}" not found</h2>
					The user you are searching does not exist.
				</v-col>
			</v-row>
		</v-alert>

		<v-alert
			v-if="doNotContact"
			prominent
			outlined
			text
			type="error"
		>
			<v-row align="center">
				<v-col class="grow">
					<h2>Blacklisted</h2>
					Do not contact this profile!
				</v-col>
			</v-row>
		</v-alert>

		<ProfileAdmins
				:admins="profileAdmins"
				ref="ProfileAdmins"
				:type="type"
				:at_name="at_name"
		/>
		<v-row v-if="!not_found" justify="center">
			<v-col cols="8">
				<v-row>
					<v-col  class="pt-0">
						<ValidationObserver
							ref="profile"
							@submit.prevent="save"
						>
							<form autocomplete="off">
								<v-card>
									<div class="upload-background-cropper-wrapper">
										<cropper
											class="upload-background-cropper"
											:src="editForm.background_image || `https://storage.googleapis.com/wdht-media/2bc99481fae14d88a0bd3131e130777f.jpg`"
											ref="backgroundCropper"
											:stencil-props="{
											handlers: {},
											movable: false,
											scalable: false,
											aspectRatio: 1920/1080,
										}"
											image-restriction="stencil"
											default-boundaries="fill"
											:defaultBoundaries="()=> ({ width: 1468, height: 420 })"
										></cropper>
										<Button
											v-if="editing"
											@click="$refs.backgroundFile.click()"
											style="position:absolute;top:15px;right:15px;"
											fab
											small
											color="primary"
										>
											<input type="file" ref="backgroundFile" @change="loadImage($event, `background`)" accept=".png, .jpg, .jpeg" class="d-none">
											<span class="text-subtitle-1"><svg-icon icon="camera" /></span>
										</Button>


										<div style="position:absolute;bottom:15px;right:15px;">
											<Button
												v-if="editing"
												class="align-self-end ma-4"
												color="primary"
												type="submit"
												:loading="saveLoading"
											>
												Save
											</Button>

											<Button
												v-if="!editing && editable && $authorized([`manager`,`marketing`])"
												class="align-self-end ma-4"
												@click="edit()"

											>
												Edit
											</Button>
											<Button v-else-if="editing" @click="cancel()" class="align-self-end ma-4">
												Cancel
											</Button>
										</div>






										<div class="upload-profile-cropper-wrapper">
											<cropper
												class="upload-profile-cropper"
												stencil-component="circle-stencil"
												:src="editForm.profile_image"
												ref="profileCropper"
												:stencil-props="{
												handlers: {},
												movable: false,
												scalable: false,
												aspectRatio: 1,
											}"
												image-restriction="stencil"
												:defaultBoundaries="()=> ({ width: 120, height: 120 })"
											/>

											<Button
												v-if="editing"
												@click="$refs.profileFile.click()"
												style="position:absolute;bottom:10px;right:0px;"
												fab
												small
												color="primary"
											>
												<input type="file" ref="profileFile" @change="loadImage($event, `profile`)" accept=".png, .jpg, .jpeg" class="d-none">
												<span class="text-subtitle-1"><svg-icon icon="camera" /></span>
											</Button>
										</div>
									</div>

									<v-card-text v-if="editing">
										<v-row class="pt-2">
											<v-col>
												<VTextFieldValidation rules="required" label="Display name" v-model="editForm.display_name" />
											</v-col>
										</v-row>
										<v-row>
											<v-col>
												<VTextFieldValidation rules="required" label="@at_name" v-model="editForm.at_name" />
											</v-col>
										</v-row>

										<v-row>
											<v-col class="text-body-2">
												<VTextareaValidation label="Bio" v-model="editForm.bio" rows="2" />
											</v-col>
										</v-row>

										<v-row>
											<v-col class="text-body-2">
												<v-autocomplete
													v-model="user_role.selected"
													:items="user_role.items"
													item-text="name"
													item-value="value"
													chips
													label="User roles"
													multiple
													placeholder=" "
												></v-autocomplete>
											</v-col>
										</v-row>
									</v-card-text>


									<v-card-text v-if="!editing">
										<v-row no-gutters>
											<v-col class="d-flex">
												<div class="d-flex flex-column">
											<span class="subtitle-1 font-weight-bold">
												{{ display_name }}
												<svg-icon v-if="isClaimed()" class="primary--text ml-2 align-self-center" icon="verified" />
												<svg-icon v-if="climate_dialogue && climate_dialogue.enabled" class="primary--text ml-2 align-self-center" icon="climate-dialogue" />
												<template>
													<span v-if="social_associations.apple" style="color:#000000;"><svg-icon class="ml-2 align-self-center" icon="apple" /></span>
													<span v-if="social_associations.twitter" style="color:#1DA1F2;"><svg-icon class="ml-2 align-self-center" icon="twitter" /></span>
													<span v-if="social_associations.facebook" style="color:#4267B2;"><svg-icon class="ml-2 align-self-center" icon="facebook" /></span>
													<span v-if="social_associations.google"><svg-icon class="ml-2 align-self-center" icon="google-color" /></span>
												</template>
												({{ type }} account)
											</span>
											<span>
												@{{ at_name }} {{ email && $authorized([`manager`,`marketing`]) && `(${email})` }}
											</span>
											<span v-if="$authorized()">
												ID: {{ user_id }}
											</span>
											<span class="text-body-2">
												Bio: {{ bio }}
											</span>
												</div>
												<v-spacer />
												<template v-if="$authorized([`manager`,`marketing`])">
													<Button text v-if="type === `private`" class="mr-2" @click="this.showAdmins">Admin to {{this.profileAdmins.length}}</Button>
													<Button text v-else-if="type === `public`" class="mr-2" @click="this.showAdmins">{{this.profileAdmins.length}} admins</Button>
													<v-menu offset-y>
														<template v-slot:activator="{ on }">
															<Button
																icon
																v-on="on"
																class="ml-2"
															>
																<v-icon>mdi-dots-horizontal</v-icon>
															</Button>
														</template>
														<v-list>
															<v-list-item :href="`${env.VUE_APP_APP_URL}/profile/${at_name}`" target="_blank">
																<v-list-item-title>View in app</v-list-item-title>
															</v-list-item>
															<v-list-item v-if="climate_dialogue && climate_dialogue.enabled" :href="`${env.VUE_APP_APP_URL}/climatedialogue/${at_name}`" target="_blank">
																<v-list-item-title>View CD page</v-list-item-title>
															</v-list-item>
															<v-divider />
															<v-list-item @click="publishPost">
																<v-list-item-title>Publish post...</v-list-item-title>
															</v-list-item>
															<v-list-item @click="sendSystemMessage">
																<v-list-item-title>Send system message...</v-list-item-title>
															</v-list-item>
															<v-list-item @click="()=>{}" v-if="$authorized() && false">
																<JsonExcel :fetch="downloadFollowers">
																	<v-list-item-title>Download followers</v-list-item-title>
																</JsonExcel>
															</v-list-item>
															<v-list-item @click="getToken" v-if="editable && $authorized([`manager`])">
																<v-list-item-title>Copy Token</v-list-item-title>
															</v-list-item>
															<v-list-item @click="mergeTo">
																<v-list-item-title>Merge to...</v-list-item-title>
															</v-list-item>
															<v-list-item v-if="can_create_articles" @click="disableArticles">
																<v-list-item-title>Disable Articles</v-list-item-title>
															</v-list-item>
															<v-list-item v-if="!can_create_articles" @click="enableArticles">
																<v-list-item-title>Enable Articles</v-list-item-title>
															</v-list-item>
															<template v-if="type === `public`">
																<v-list-item v-if="climate_dialogue && climate_dialogue.enabled" @click="disableClimateDialogue">
																	<v-list-item-title>Disable Climate Dialogue</v-list-item-title>
																</v-list-item>
																<v-list-item v-if="!climate_dialogue || !climate_dialogue.enabled" @click="enableClimateDialogue">
																	<v-list-item-title>Enable Climate Dialogue</v-list-item-title>
																</v-list-item>
															</template>
															<v-divider />
															<v-list-item @click="execDoNotContact" >
																<v-list-item-title>
																	{{ doNotContact ? `Remove Do Not Contact` : `Do Not Contact...` }}
																</v-list-item-title>
															</v-list-item>
															<v-list-item @click="deleteProfile" >
																<v-list-item-title class="error--text">
																	Delete account...
																</v-list-item-title>
															</v-list-item>
														</v-list>
													</v-menu>
												</template>
											</v-col>
										</v-row>

										<template v-if="!editing">

											<v-row no-gutters v-if="user_role.selected">
												<v-col>
													Roles: <span v-for="(role, i) in user_role.selected" :key="i"><span class="font-weight-bold">{{ role }}</span>, </span>
												</v-col>
											</v-row>

											<v-row no-gutters v-if="feed_languages">
												<v-col>
													Feed languages: <span v-for="(language, i) in feed_languages" :key="i"><span class="font-weight-bold">{{ language }}</span>, </span>
												</v-col>
											</v-row>

											<v-row no-gutters>
												<v-col v-if="false">
													<a class="mr-2 text-caption" @click="getFollowing"><span class="font-weight-bold">{{ following }}</span> following</a>
													<a class="mr-2 text-caption" @click="getFollowers"><span class="font-weight-bold">{{ followers }}</span> followers</a>
												</v-col>
												<v-col>
													<span class="text-caption font-weight-bold">{{ following }}</span> following
													<span class="ml-2 text-caption font-weight-bold">{{ followers }}</span> followers
												</v-col>
											</v-row>

											<v-row>
												<v-col>
													<Categories :selected="profile.categories" :item="profile" />
												</v-col>
											</v-row>
										</template>
									</v-card-text>
								</v-card>
							</form>
						</ValidationObserver>
					</v-col>
				</v-row>
				<v-row>
					<v-col>

						<v-card v-if="!editing">
							<v-tabs v-model="tab">
								<v-tab key="1" v-if="type === `public`">
									Reviews
								</v-tab>
								<v-tab key="2">
									Posted
								</v-tab>
								<v-tab key="3">
									Commented
								</v-tab>
								<v-tab key="5">
									Messages
								</v-tab>
								<v-tab key="4" v-if="climate_dialogue && climate_dialogue.enabled">
									Climate Dialogue
								</v-tab>
							</v-tabs>
							<v-divider style="background-color:#19cd9b;" />

							<v-tabs-items v-model="tab">
								<v-card-text>
									<v-tab-item key="1" v-if="type === `public`">
										<Post v-for="(post, i) in reviews" :key="i" :post="post" preview />
										<v-btn @click="loadMoreReviews" :loading="reviewsLoading">Load more</v-btn>
									</v-tab-item>

									<v-tab-item key="2">
										<Post v-for="(post, i) in posts" :key="i" :post="post" preview />
										<v-btn @click="loadMorePosts" :loading="postsLoading">Load more</v-btn>
									</v-tab-item>

									<v-tab-item key="3">
										<CommentSection :comments="comments" />
										<v-btn @click="loadMoreComments" :loading="commentsLoading">Load more</v-btn>
									</v-tab-item>

									<v-tab-item key="5">
										<Message v-for="(message, i) in system_messages" :key="i" :message="message" />
									</v-tab-item>

									<v-tab-item key="4">
										<v-form>
											<v-container>
												<v-textarea
													v-if="climate_dialogue"
													v-model="climate_dialogue.idea_helper_text"
													label="Idea helper text"
													value=""
													maxlength="1000"
													hide-details
													outlined
													placeholder=" "
												/>
											</v-container>
											<v-container>
												<Button type="submit" @click="saveClimateDialogue">Save</Button>
											</v-container>
										</v-form>
									</v-tab-item>
								</v-card-text>
							</v-tabs-items>
						</v-card>
					</v-col>
				</v-row>

			</v-col>

			<v-col cols="4">
				<v-row>
					<v-col class="pt-0">
						<v-card>
							<v-card-text class="text-right">
								<template v-if="created"><span>Created:</span> <span class="font-weight-bold text-caption">{{ created }}</span><br></template>
								<template v-if="modified"><span>Modified:</span> <span class="font-weight-bold text-caption">{{ modified }}</span><br></template>
								<template v-if="isClaimed()"><span>Claimed:</span> <span class="font-weight-bold text-caption">{{ claimed }}</span><br></template>
								<template v-if="tos_accepted"><span>TOS Accepted:</span> <span class="font-weight-bold text-caption">{{ tos_accepted }}</span><br></template>
								<template v-if="email_confirmed"><span>Email confirmed:</span> <span class="font-weight-bold text-caption">{{ email_confirmed }}</span><br></template>
								<template v-if="suggestion_showed"><span>Suggestions:</span> <span class="font-weight-bold text-caption">{{ suggestion_showed }}</span><br></template>
								<template v-if="last_activity"><span>Last Activity:</span> <span class="font-weight-bold text-caption">{{ last_activity }}</span><br></template>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>

				<v-row >
					<v-col>
						<v-card>
							<v-card-text>
								<span class="text-subtitle-1 font-weight-bold">Statistics</span>
								<div><span class="font-weight-bold text-caption">{{ actions.posts }}</span> posts</div>
								<div><span class="font-weight-bold text-caption">{{ actions.comments }}</span> comments</div>
								<div><span class="font-weight-bold text-caption">{{ actions.shares }}</span> shares</div>
								<div><span class="font-weight-bold text-caption">{{ actions.post_agrees }}</span> post agrees</div>
								<div><span class="font-weight-bold text-caption">{{ actions.comment_agrees }}</span> comment agrees</div>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>

				<v-row v-if="merges.length">
					<v-col>
						<v-card>
							<v-card-text>
								<span class="text-subtitle-1 font-weight-bold">Merges</span>
								<div v-for="(merge,i) in merges" :key="i">
									<svg-icon :icon="merge.status === `merged` ? `check-circle` : `cross-circle`" :color="merge.status === `merged` ? `green` : `red`" />
									<span class="font-weight-bold text-caption"> {{ merge.merged_at }}</span>
									<div class="text-caption"> {{ merge.replace_account.at_name }} > {{ merge.keep_account.at_name }}</div> at
									<v-divider v-if="i+1 < merges.length" class="my-2"/>
								</div>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>

				<v-row no-gutters v-if="merged_sources">
					<v-col>
						<v-card>
							<v-card-text>
								<span class="text-subtitle-1 font-weight-bold">Merged accounts</span>
								<div v-for="(source,i) in merged_sources" :key="i">
									<span>{{ source.id }} ({{ source.source }})</span>
								</div>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>

				<v-row >
					<v-col>
						<v-card>
							<v-card-text>
								<span class="text-subtitle-1 font-weight-bold">Features</span>
								<div><svg-icon :icon="can_create_articles ? `check-circle` : `cross-circle`" :color="can_create_articles ? `green` : `red`" /> Article</div>
								<div><svg-icon :icon="use_current_location ? `check-circle` : `cross-circle`" :color="use_current_location ? `green` : `red`" /> Use location</div>
								<div v-if="type === `public`"><svg-icon :icon="climate_dialogue && climate_dialogue.enabled ? `check-circle` : `cross-circle`" :color="climate_dialogue && climate_dialogue.enabled ? `green` : `red`" /> Climate Dialogue</div>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>

				<v-row v-if="plantedTrees && plantedTrees.count > 0">
					<v-col>
						<v-card>
							<v-card-text>
								<span class="text-subtitle-1 font-weight-bold">{{plantedTrees.count}} planted trees</span>
								<template v-for="(count,type) in plantedTrees.subcount">
									<div v-bind:key="type"><span class="font-weight-bold text-caption">{{count}}</span> for {{type}}</div>
								</template>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>

				<v-row v-if="totalEngagement.series">
					<v-col>
						<v-card>
							<v-card-text>
								<Highchart :options="totalEngagement" />
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<Dialog ref="dialog">
			<v-list subheader>
				<v-list-item
						v-for="item in followList"
						:key="item.account.at_name"
						@click="$router.push({name: `Profile`, params: {id: item.account.at_name}})"
				>
					<v-list-item-avatar>
						<img :src="item.account.small_profile_image" />
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title v-text="item.account.at_name" />
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</Dialog>

		<NewPost ref="newpost" />

		<MergeAccounts :type="type" ref="mergeAccounts" />


		<NewSystemMessages ref="systemMessages" />
	</v-container>

	<Loader v-else />
</template>

<style type="scss">
.upload-background-cropper-wrapper {
	position:relative;
	height: 320px;
	width: 100%;
}

.upload-background-cropper {
	height: 100%;
	width: 100%;
	overflow:hidden;
	background: #eee;
}

.upload-profile-cropper-wrapper {
	position:absolute;
	bottom:20px;
	left:20px;
}

.upload-profile-cropper {
	border: solid 1px #EEE;
	border-radius: 50%;
	height: 110px;
	width: 110px;
	overflow:hidden;
	background: #e3e3e3;
}
</style>

<script>
import { globalMixIn } from '@/App'
import Dialog from '@/components/material/Dialog'
import Post from '@/components/material/Post'
import Message from '@/components/material/Message'
import CommentSection from '@/components/material/Comment'
import ProfileAdmins from '@/components/material/ProfileAdmins'
import NewPost from '@/components/material/NewPost'
import MergeAccounts from '@/components/dialogs/MergeAccounts'
import Categories from "@/components/material/Categories"
import Highchart from '@/components/material/Highchart'
import Loader from "@/components/core/Loader"
import VTextFieldValidation from '@/components/validation/VTextField'
import VTextareaValidation from '@/components/validation/VTextarea'
import { Cropper } from 'vue-advanced-cropper'
import NewSystemMessages from "@/components/dialogs/NewSystemMessages"
import JsonExcel from "vue-json-excel"

export default {
	components: {
		Loader,
		Dialog,
		Post,
		Message,
		CommentSection,
		ProfileAdmins,
		NewPost,
		MergeAccounts,
		Highchart,
		VTextFieldValidation,
		VTextareaValidation,
		Cropper,
		NewSystemMessages,
		JsonExcel,
		Categories,
	},
	computed: {
		form() {
			let data = {
				bio: this.editForm.bio,
				display_name: this.editForm.display_name,
				at_name: this.editForm.at_name,
			}
			if (this.background_image_refreshed) data.background_image = this.background_image
			if (this.profile_image_refreshed) data.profile_image = this.profile_image
			return data
		},
	},
	data() {                                   // <== changed this line
		return {
			commentsPage: 1,
			reviewsPage: 1,
			postsPage: 1,
			commentsLoading: false,
			reviewsLoading: false,
			postsLoading: false,
			profile: {},
			actions: {},
			at_name: null,
			bio: null,
			background_image: null,
			background_image_refreshed: false,
			user_role: {
				items: [],
				selected: [],
			},
			can_create_articles: false,
			use_current_location: false,
			claimed: null,
			climate_dialogue: {
				enabled: false,
			},
			system_messages: [],
			created: null,
			doNotContact: false,
			editable: false,
			not_found: false,
			merged_sources: [],
			feed_languages: [],
			display_name: null,
			email: null,
			followers: null,
			following: null,
			modified: null,
			profile_image: null,
			profile_image_refreshed: false,
			social_associations: {},
			email_confirmed: null,
			type: null,
			user_id: null,
			merges: [],

			editing: false,
			loading: true,
			saveLoading: false,
			plantedTrees: {},
			totalEngagement: {},
			tab: null,
			tos_accepted: null,
			follow: {},
			reviews: [],
			posts: [],
			comments: [],
			profileAdmins: {},
			followList: [],
			env: process.env,
			suggestion_showed: null,
			last_activity: null,
			editForm: {
				bio: null,
				at_name: null,
				display_name: null,
				profile_image: null,
				background_image: null,
			},
		}
	},
	methods: {
		loadMorePosts() {
			this.postsPage++
			this.getPosts()
		},
		loadMoreReviews() {
			this.reviewsPage++
			this.getReviews()
		},
		loadMoreComments() {
			this.commentsPage++
			this.getComments()
		},
		loadImage(event, target) {
			const input = event.target
			if (input.files && input.files[0]) {
				const reader = new FileReader()
				reader.onload = (e) => {
					if (target === `background`) {
						this.editForm.background_image = e.target.result
						this.background_image_refreshed = true
					}
					if (target === `profile`) {
						this.editForm.profile_image = e.target.result
						this.profile_image_refreshed = true
					}
				}
				reader.readAsDataURL(input.files[0])
			}
		},
		uploadImage(resolve, reject, target) {
			if (target === `profile` && !this.profile_image_refreshed) {
				resolve()
				return
			}
			if (target === `background` && !this.background_image_refreshed) {
				resolve()
				return
			}
			const vueImage = target === `profile` ? this.$refs.profileCropper : this.$refs.backgroundCropper
			this.$uploadImage(vueImage).then(res => {
				if (target === `background`) this.background_image = res.result.thumbnails.original.url
				if (target === `profile`) this.profile_image = res.result.thumbnails.original.url
				resolve()
			})
		},
		saveRoles() {
			const original = (this.profile.user_roles ?? []).map(a => a.role)
			const modified = this.user_role.selected
			const added = modified.filter(x => !original.includes(x))
			const removed = original.filter(x => !modified.includes(x))

			added.forEach(value => this.$httpInt.put(`/v2/app/account/${this.at_name}/roles/${value}`))
			removed.forEach(value => this.$httpInt.delete(`/v2/app/account/${this.at_name}/roles/${value}`))
		},
		publishPost() {
			this.$refs.newpost.open({ author: this.at_name })
		},
		async downloadFollowers() {
			const response = await this.$httpInt.get(`/v2/app/account/${this.$route.params.id}/followers`);
			return response.data.result;
		},
		sendSystemMessage() {
			this.$refs.systemMessages.open({
				uuid: this.user_id,
				at_name: this.at_name,
			})
		},
		execDoNotContact () {
			if (this.doNotContact) {
				this.$httpInt.delete(`/v2/profile/do-not-contact/${this.$route.params.id}`)
					.then(() => {
						this.$root.$snackbar(`Removed Do Not Contact!`)
						this.doNotContact = false
					})
			} else {
				this.$root.$confirm(`Blacklist @${this.at_name}?`, `Do you want to put @${this.at_name} in a Do Not Contact list?`, { color: `error` })
					.then(confirm => {
						if (confirm) {
							this.$httpInt.post(`/v2/profile/do-not-contact`, { at_name: this.$route.params.id })
								.then(() => {
									this.$root.$snackbar(`Set as Do Not Contact!`)
									this.doNotContact = true
								})
						}
					})
			}

		},
		deleteProfile () {
			this.$root.$confirm(`Delete @${this.at_name}?`, `Are you sure you want to delete @${this.at_name}?`, { color: `error` })
				.then(confirm => {
					if (confirm) {
						this.$httpInt.delete(`/v2/app/account/${this.at_name}`)
							.then(() => this.$root.$snackbar('Profile deleted'))
							.finally(() => this.$router.go())
					}
				})
		},
		disableClimateDialogue() {
			this.$httpInt.patch(`/v2/app/account/${this.at_name}`, { climate_dialogue: false })
				.then(() => {
					this.$root.$snackbar(`Climate Dialogue disabled for "${this.at_name}"`)
					this.climate_dialogue.enabled = false
				})
		},
		disableArticles() {
			this.$httpInt.patch(`/v2/app/account/${this.at_name}`, { can_create_articles: false })
				.then(() => {
					this.$root.$snackbar(`Articles disabled for "${this.at_name}"`)
					this.can_create_articles = false
				})
		},
		mergeTo() {
			this.$refs.mergeAccounts.open(this.at_name)
		},
		getToken() {
			this.copyToken(this.$route.params.id)
				.then(token => this.$root.$confirm(`Token`, token, { type: `info` }).then(() => {}))
				.catch(e => this.$root.$snackbar(e.message, { color: `error` }))
		},
		enableClimateDialogue() {
			this.$httpInt.patch(`/v2/app/account/${this.at_name}`, { climate_dialogue: true })
				.then(() => {
					this.enableArticles()
					this.$root.$snackbar(`Climate Dialogue enabled for "${this.at_name}"`)
					this.climate_dialogue.enabled = true
				})
		},
		enableArticles() {
			this.$httpInt.patch(`/v2/app/account/${this.at_name}`, { can_create_articles: true })
				.then(() => {
					this.$root.$snackbar(`Articles enabled for "${this.at_name}"`)
					this.can_create_articles = true
				})
		},
		refreshProfile() {
			this.loading = true
			this.$httpInt.get(`/v2/app/account/${this.$route.params.id}`)
				.then(res => {
					const data = res.data.result
					this.profile = data
					this.user_role.selected = (data.user_roles ?? []).map(a => a.role)
					this.at_name = data.at_name
					this.bio = data.bio
					this.background_image = data.background_image
					this.editForm.background_image = data.background_image
					this.can_create_articles = data.can_create_articles ?? false
					this.use_current_location = data.use_current_location ?? false
					this.created = data.creation_date
					this.modified = data.date_modified
					this.feed_languages = data.feed_languages
					this.type = data.type

					this.user_id = data.user_id
					this.profile_image = data.profile_image
					this.editForm.profile_image = data.profile_image
					this.last_activity = data.last_activity_timestamp
					this.display_name = data.display_name
					this.tos_accepted = data.tos_accepted
					this.claimed = data.claim_date
					this.editable = this.isClaimed() || data.type === `private`
					this.climate_dialogue = data.climate_dialogue ?? {}
					this.followers = data.followers
					this.following = data.following
					this.social_associations = data.social_associations
					this.email = data.email
					this.email_confirmed = data.email_confirmed_at
					this.suggestion_showed = data.last_suggestion


					this.$httpInt.get(`/v2/app/account/${this.$route.params.id}/statistics`)
						.then(res => this.actions = res.data.result.actions)
					//this.merged_sources = res.data.merged_sources
					//this.merges = res.data.merges

					this.getPosts()
					this.getMessages()
					this.getReviews()
					this.getComments()
					this.getAdmins()
					this.getPlantedTrees()
					this.getDoNotContact()
					this.getMessages()
				})
				.catch(err => {
					if (err.response.status === 404) this.not_found = true
				})
				.finally(() => this.loading = false)
		},
		isClaimed() {
			return this.claimed ? true : false
		},
		getPosts() {
			this.postsLoading = true
			this.$httpInt.get(`/v2/app/posts/?author=${this.at_name}&include=comments&sort_by=date_created&sort_by_desc=true&per_page=50&page=${this.postsPage}`)
				.then(res => this.posts.push(...res.data.result))
				.finally(() => this.postsLoading = false)
		},
		getMessages() {
			this.$httpInt.get(`/v2/app/messages/?user_id=${this.user_id}&sort_by=creation_date&sort_by_desc=true`)
				.then(res => this.system_messages = res.data.result)
		},
		getReviews() {
			this.reviewsLoading = true
			this.$httpInt.get(`/v2/app/posts/?recipient=${this.at_name}&include=comments&per_page=50&page=${this.reviewsPage}`)
				.then(res => this.reviews.push(...res.data.result))
				.finally(() => this.reviewsLoading = false)
		},
		getComments() {
			this.commentsLoading = true
			this.$httpInt.get(`/v2/app/comments?author=${this.at_name}&per_page=50&page=${this.commentsPage}&sort_by=date_created&sort_by_desc=true`)
				.then(res => this.comments.push(...res.data.result))
				.finally(() => this.commentsLoading = false)
		},
		getFollowing() {
			/*
			this.$httpInt.get(`wdht/follow/following/${this.at_name}`)
				.then(res => {
					this.followList = res.data.results
					this.$refs.dialog.open()
				})
			 */
		},
		getFollowers() {
			/*
			this.$httpInt.get(`wdht/follow/followers/${this.at_name}`)
				.then(res => {
					this.followList = res.data.results
					this.$refs.dialog.open()
				})
			 */
		},
		getAdmins() {
			this.$httpInt.get(`/v2/app/account/${this.at_name}/admins`)
				.then(res => this.profileAdmins = res.data.result)
		},
		getPlantedTrees() {
			this.$httpInt.get(`/statistics/list-planted-trees/?timerange=allHistory&planted_for_user_id=${this.user_id}`)
				.then(res => this.plantedTrees = { count: res.data.count, subcount: res.data.subcount })
		},
		getDoNotContact() {
			this.$httpInt.get(`/v2/profile/do-not-contact/${this.at_name}`)
				.then(res => this.doNotContact = res.data.result.do_not_contact)
		},
		saveClimateDialogue() {
			this.$httpInt.patch(`/v2/app/account/${this.at_name}`, {
				idea_helper_text: this.climate_dialogue.idea_helper_text
			})
		},
		showAdmins() {
			this.$refs.ProfileAdmins.open()
				.then(() => this.getAdmins())
				.catch(() => {})
		},
		cancel() {
			this.editForm.background_image = this.background_image
			this.background_image_refreshed = false
			this.editForm.profile_image = this.profile_image
			this.profile_image_refreshed = false
			this.editing = false
		},
		edit() {
			this.editForm.bio = this.bio
			this.editForm.at_name = this.at_name
			this.editForm.display_name = this.display_name
			this.editForm.profile_image = this.profile_image
			this.editForm.background_image = this.background_image
			this.editing = true

			this.$httpInt.get(`/v2/app/account/roles`)
				.then(res => this.user_role.items = res.data.result)

		},
		save() {
			this.$refs.profile.validate().then(valid => {
				if (!valid) return
				this.saveLoading = true
				this.saveRoles()
				const promise1 = new Promise((resolve, reject) => this.uploadImage(resolve, reject, `profile`))
				const promise2 = new Promise((resolve, reject) => this.uploadImage(resolve, reject, `background`))

				Promise.all([promise1, promise2]).then(() => {
					this.$httpInt.patch(`/v2/app/account/${this.$route.params.id}`, this.form)
						.then(() => this.$router.push({name: `Profile`, params: {id: this.editForm.at_name}}))
						.finally(() => {
							this.editing = false
							this.saveLoading = false
							this.background_image_refreshed = false
							this.profile_image_refreshed = false
						})
				})
			})
		},
		reset() {
			Object.assign(this.$data, this.$options.data.apply(this))
		},
	},
	mixins: [globalMixIn],
	mounted () {
		this.reset()
		this.refreshProfile()
		/*
		this.getApi(`${process.env.VUE_APP_API_URL}/api/statistics/total-engagement/${this.$route.params.id}`)
			.then(res => {
				if (!res.data) return
				this.totalEngagement = {
					series: [{
						data: res.data[0],
						name: 'Engagement',
					}]
				}
			})
		 */
	},
	name: `CommunityProfile`,
	watch: {
		$route () {
			this.reset()
			this.refreshProfile()
			this.$refs.dialog.close()
		}
	},
}
</script>
